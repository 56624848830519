import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kiosk',
  templateUrl: './kiosk.component.html',
  styleUrls: ['./kiosk.component.scss']
})
export class KioskComponent implements OnInit {
  public hideEmployeeSurvey: boolean;

  public ngOnInit(): void {
    const today: Date = new Date();

    /** Usually the business team gives us a start and end date
     * Sometimes they give us start and end times along with a timezone
     *
     * Write the date and time in ISO-8601 format (https://en.wikipedia.org/wiki/ISO_8601)
     * Provide the time in YYYY-MM-DDT00:00:00-0700
     * Where:
     *    YYYY = Year
     *    MM = Month (01 = Jan, 02 = Feb, ..., 12 = Dec)
     *    DD = Date (01 thru 31)
     *    T00:00:00-0700 = Midnight at MST (Mountain Timezone i.e. time at Phoenix, AZ)
     *
     * Arizona does not observe any daylight savings
     */
    const surveyStart: Date = new Date('2024-09-15T21:00:00-0700');
    const surveyEnd: Date = new Date('2024-10-02T21:00:00-0700'); // Midnight EDT / EST of the date after the expiry date

    this.hideEmployeeSurvey = today < surveyStart || today > surveyEnd;
  }
}
