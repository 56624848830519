import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-external-sites',
  templateUrl: './externalSites.component.html',
  styleUrls: ['./externalSites.component.scss']
})
export class ExternalSitesComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
