import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDividerModule, MatGridListModule } from '@angular/material';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { PermissionDeniedComponent } from './components/permission-denied/permission-denied.component';
import { AccessWebmailComponent } from './containers/access-webmail/access-webmail.component';
import { EEOComponent } from './containers/eeo/eeo.component';
import { ExternalSitesComponent } from './containers/externalSites/externalSites.component';
import { KioskComponent } from './containers/kiosk/kiosk.component';
import { MyrsgComponent } from './containers/myrsg/myrsg.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    PermissionDeniedComponent,
    MyrsgComponent,
    ExternalSitesComponent,
    KioskComponent,
    EEOComponent,
    AccessWebmailComponent
  ],
  exports: [HeaderComponent, FooterComponent, PermissionDeniedComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatToolbarModule,
    MatDividerModule,
    MatGridListModule
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        `CoreModule has already been loaded. Import Core modules in the AppModule only.`
      );
    }
  }
}
