export const environment = {
  production: false,
  name: 'dev',
  apiBaseUrl: 'https://i3js66alr5.execute-api.us-west-2.amazonaws.com/dev',

  coveo: {
    apiKey: 'xx8e61b313-02c7-4180-ab36-bb84fec2486a',
    orgId: 'republicservicesnonproduction37vhfml2',
    apiUrlBase: 'https://platform.cloud.coveo.com/rest/search',
    sources: ['@source=="consolidated-knowledge-dev"']
  }
};
