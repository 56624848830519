import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from '@component/page-not-found/page-not-found.component';
import { PermissionDeniedComponent } from '@component/permission-denied/permission-denied.component';
import { EEOComponent } from '@container/eeo/eeo.component';
import { ExternalSitesComponent } from '@container/externalSites/externalSites.component';
import { KioskComponent } from '@container/kiosk/kiosk.component';
import { MyrsgComponent } from '@container/myrsg/myrsg.component';
import { AccessWebmailComponent } from './core/containers/access-webmail/access-webmail.component';

const appRoutes: Routes = [
  { path: 'permission-denied', component: PermissionDeniedComponent },
  {
    path: '',
    loadChildren: './demo-dashboard/demo-dashboard.module#DemoDashboardModule',
    pathMatch: 'full'
  },
  { path: 'myrsg', component: MyrsgComponent },
  { path: 'kiosk', component: KioskComponent },
  { path: 'external-links', component: ExternalSitesComponent },
  { path: 'eeo', component: EEOComponent },
  { path: 'accesswebmail', component: AccessWebmailComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
    })
  ],
  declarations: [PageNotFoundComponent],
  exports: [RouterModule]
})
export class AppRoutingModule {}
